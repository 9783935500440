import { useRef, useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const SelectInput = ({
  name,
  placeholder,
  options,
  isMultiSelect,
  isSearchable,
  onChange,
  className,
  defaultValueProp,
  disabled = false,
  isClearable = true,
  valueProp,
  labelProp,
}) => {
  const animatedComponents = makeAnimated();
  const [resetKey, setResetKey] = useState(0);
  const [selectOptions, setSelectOptions] = useState();
  const [defaultValueState, setDefaultValueState] = useState();
  const isFirstRender = useRef(true);

  useEffect(() => {
    let inputOptions = selectOptions;

    if (isFirstRender.current) {
      isFirstRender.current = false;
      inputOptions = buildOptions();
    }

    const valueState = getDefaultValueProp(inputOptions);
    setDefaultValueState(valueState);

    setTimeout(() => {
      handleReset();
    }, 0);
  }, [defaultValueProp, options]);

  useEffect(() => {
    if (!isFirstRender.current) {
      buildOptions();
    }
  }, [options]);

  const getDefaultValueProp = (inputOptions) => {
    const val =
      defaultValueProp !== null
        ? isMultiSelect
          ? inputOptions
            ? inputOptions?.filter((x) => defaultValueProp[0]?.includes(x.value))
            : null
          : inputOptions
          ? inputOptions?.find((x) => x.value == defaultValueProp)
          : null
        : null;

    return val;
  };

  const handleChange = (e) => {
    let event;
    if (!isMultiSelect) {
      event = {
        target: {
          name: name,
          value: e?.value,
        },
      };
    } else {
      event = {
        target: {
          name: name,
          value: e.map((x) => x.value),
        },
      };
    }

    onChange(event);
  };

  const handleReset = () => {
    setResetKey((prevKey) => prevKey + 1);
  };

  const buildOptions = () => {
    let inputOptions = null;
    if (valueProp || labelProp) {
      inputOptions = options?.map((x) => {
        return {
          value: x[valueProp],
          label: x[labelProp],
        };
      });
    } else {
      inputOptions = options;
    }
    setSelectOptions(inputOptions);
    return inputOptions;
  };

  return (
    <Select
      key={resetKey}
      closeMenuOnSelect={!isMultiSelect}
      components={animatedComponents}
      options={selectOptions || []}
      isMulti={isMultiSelect}
      isSearchable={isSearchable}
      isClearable={isClearable}
      onChange={handleChange}
      defaultValue={defaultValueState}
      //
      isDisabled={disabled}
      isLoading={false}
      placeholder={placeholder}
      className={{ className }}
      classNamePrefix={className}
      styles={{ whiteSpace: "nowrap", menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      menuPortalTarget={document.body}
    />
  );
};

export default SelectInput;
