import React, { lazy, useState } from "react";
import { NavLink } from "react-router-dom";
import { UncontrolledDropdown, Nav, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import "./Sidebar.scss";
import { StyledLink } from "components/shared/StyledLink";
import Auth0Helper from "utils/Auth0Helper";
import Logo_white from "../../../assets/img/logo-blanco.svg";
import PerfectScrollbar from "perfect-scrollbar";
import packageJson from "../../../../package.json";

var ps;

function Sidebar(props) {
  const sidebar = React.useRef();
  const { validateUserPermissions } = Auth0Helper();
  const [routeLinks, setRouteLinks] = useState([]);

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    setTimeout(() => {
      validateAndSetRoutes(props.routes);
    });

    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  }, [routeLinks]);

  const validateAndSetRoutes = async (routes) => {
    routes.forEach(async (r) => {
      r.userHasRole = await validateUserPermissions(r.scopes);

      if (r.childrens && r.childrens.length > 0) {
        r.childrens.forEach(async (child) => {
          child.userHasRole = await validateUserPermissions(child.scopes);
        });
      }
    });

    setRouteLinks(routes);
  };

  return (
    <div
      className={`sidebar ${!props.isExpanded && "sidebar-small"}`}
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <StyledLink to={`/${props.layoutName}/home`} className="logo d-flex">
        {props.isExpanded ? (
          <>
            <div className="simple-text col-6 mr-3">
              <div className="logo-img">
                <img src={Logo_white} alt="react-logo" />
              </div>
            </div>
            <span className="simple-text logo-normal col-6 header-text global-font-bold global-primary-font-color">
              {props.layoutName}
            </span>
          </>
        ) : (
          <div className="simple-text header-logo ">
            <img src={Logo_white} alt="react-logo" />
          </div>
        )}
      </StyledLink>
      <div className="sidebar-wrapper" ref={sidebar}>
        <Nav>
          {routeLinks
            .filter((x) => x.showInSidebar && x.userHasRole)
            .map((prop, key) => {
              return !prop.childrens ? (
                <li className={activeRoute(prop.path) + (prop.pro ? " active-pro" : "")} key={key}>
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link nav-link-custom"
                    activeClassName="active"
                  >
                    <i className={`${prop.icon} icon-nav`} />
                    {props.isExpanded && <p>{prop.name}</p>}
                  </NavLink>
                </li>
              ) : (
                <li className={activeRoute(prop.path) + (prop.pro ? " active-pro" : "")} key={key}>
                  <UncontrolledDropdown id={`toggle_${key}`} direction="right">
                    <DropdownToggle caret tag="a" className="nav-link dropDown-item-custom">
                      <i className={`${prop.icon} icon-nav`} />
                      {props.isExpanded && <span className="spanMenu">{prop.name}</span>}
                    </DropdownToggle>
                    <DropdownMenu className="dropdownSubmenu">
                      {prop.childrens
                        .filter((x) => x.showInSidebar && x.userHasRole)
                        .map((propChild, keyChild) => {
                          return (
                            <StyledLink
                              key={keyChild}
                              to={propChild.layout + propChild.path}
                              className="logo d-flex"
                            >
                              <DropdownItem style={{ position: "static" }} className="spanMenu">
                                {" "}
                                <i className={`${propChild.icon} icon-nav`} /> {propChild.name}
                              </DropdownItem>
                            </StyledLink>
                          );
                        })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
              );
            })}
        </Nav>
      </div>
      <div className="version-div">v. {packageJson.version}</div>
    </div>
  );
}

export default Sidebar;
