import {
  Col,
  Input,
  Row,
  Label,
  FormGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import PropTypes from "prop-types";

const TableFooter = (props) => {
  const handlePageClick = (page) => {
    var paginationFilters = {
      pageSize: props.pageSize,
      pageNumber: page,
    };

    props.onPaginationValuesChange(paginationFilters);
  };

  const handleSizeChange = (size) => {
    var paginationFilters = {
      pageSize: parseInt(size),
      pageNumber: props.currentPage,
    };

    props.onPaginationValuesChange(paginationFilters);
  };

  const renderPaginationItems = () => {
    const paginationItems = [];
    const maxVisiblePages = 6;

    let startPage = props.currentPage - 2;
    let endPage = props.currentPage + maxVisiblePages - 1;

    if (endPage > props.totalPages) {
      endPage = props.totalPages;
      startPage = endPage - maxVisiblePages;
    }

    if (endPage < maxVisiblePages && props.totalPages > maxVisiblePages) {
      endPage = maxVisiblePages;
    }

    if (startPage < 1) {
      startPage = 0;
    }

    if (endPage - startPage > maxVisiblePages) {
      endPage--;
    }

    for (let index = startPage; index < endPage; index++) {
      paginationItems.push(
        <PaginationItem key={index} active={index === props.currentPage}>
          <PaginationLink onClick={() => handlePageClick(index)}>
            {index + 1}
          </PaginationLink>
        </PaginationItem>
      );
    }

    return paginationItems;
  };

  return (
    <tr>
      <td colSpan={props.colSpan}>
        <Row>
          <Col md={6}>
            <FormGroup row className="align-items-baseline">
              <Label for="pageSize" className="pl-3">
                Records quantity to show:
              </Label>
              <Col md={2} className="pr-0">
                <Input
                  name="pageSize"
                  type="select"
                  defaultValue={20}
                  onChange={(e) => handleSizeChange(e.target.value)}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </Input>
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <Pagination className="d-flex justify-content-end">
              <PaginationItem disabled={props.currentPage === 0}>
                <PaginationLink
                  previous
                  onClick={() => handlePageClick(props.currentPage - 1)}
                />
              </PaginationItem>

              {renderPaginationItems()}

              <PaginationItem
                disabled={props.currentPage === props.totalPages - 1}
              >
                <PaginationLink
                  next
                  onClick={() => handlePageClick(props.currentPage + 1)}
                />
              </PaginationItem>
            </Pagination>
          </Col>
        </Row>
      </td>
    </tr>
  );
};

TableFooter.propTypes = {
  totalPages: PropTypes.number,
  pageSize: PropTypes.number,
  totalItems: PropTypes.number,
  currentPage: PropTypes.number,
  onPaginationValuesChange: PropTypes.func, // funcion que se dispara al cambiar el page o size
};

export default TableFooter;
