import {
  faArrowDownShortWide,
  faArrowUpWideShort,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SortIcon = ({ field, sortConfig }) => {
  if (field === sortConfig.key) {
    if (sortConfig.direction === "asc") {
      return (
        <FontAwesomeIcon
          style={{ marginLeft: 5 }}
          icon={faArrowDownShortWide}
        />
      );
    }
    return (
      <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faArrowUpWideShort} />
    );
  }

  return null;
};

export default SortIcon;
