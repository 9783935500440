import { ALERT_TYPES } from "utils/Constants";
import {
  getFilteringOptions,
  getTransactionForRefundList,
  refundTransaction,
} from "../Api/RefundApi";
import { useAlert } from "components/shared/AlertContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Row, Table } from "reactstrap";
import CustomLoader from "components/shared/CustomLoader";
import DateInput from "components/shared/DateInput";
import SelectInput from "components/shared/SelectInput";
import SortIcon from "components/shared/SortIcon";
import { formatDate } from "utils/Utils";
import { MdCurrencyExchange } from "react-icons/md";
import TableFooter from "components/shared/TableFooter";
import ConfirmationPopup from "components/shared/ConfirmationPopup";

const RefundList = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    key: "date",
    direction: "desc",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState();
  const [filteringOptions, setFilteringOptions] = useState({});
  const [filterValues, setFilterValues] = useState({
    referenceNumber: null,
    dateFrom: new Date().toISOString().split("T")[0],
    dateTo: new Date().toISOString().split("T")[0],
    merchantId: null,
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState();
  const [messageConfirmationModal, setMessageConfirmationModal] = useState();
  const [transactionToRefund, setTransactionToRefund] = useState();

  const { getAccessTokenSilently } = useAuth0();
  const showNotification = useAlert();
  const isFirstRender = useRef(true);
  const confirmationModalTexAreaRef = useRef();

  const InitializeComponent = async () => {
    const accessToken = await getAccessTokenSilently();

    getFilteringOptions(accessToken).then(async (result) => {
      let [merchants] = result;

      await setFilteringOptions({
        merchants: merchants.data,
      });

      await sendSearchRequest();
    });

    setIsLoading(false);
  };

  const sendSearchRequest = async () => {
    setIsLoading(true);

    const accessToken = await getAccessTokenSilently();

    const requestParams = {
      ...filterValues,
      size: pageSize,
      page: currentPage,
      sortField: sortConfig.key,
      sortOrder: sortConfig.direction,
    };

    getTransactionForRefundList(accessToken, requestParams)
      .then((result) => {
        setTransactions(result.data.content);
        setTotalPages(result.data.totalPages);
      })
      .catch((error) => {
        console.error(error);
        showNotification(
          error?.response?.data?.responseMessage ?? error.message,
          ALERT_TYPES.error
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangePageOrSize = (values) => {
    setPageSize(values.pageSize);
    setCurrentPage(values.pageNumber);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handleChange = (event) => {
    let { name, value } = event.target;

    if (event.target.name == "dateFrom" || event.target.name == "dateTo") {
      value = event.target.value.toISOString().split("T")[0];
    } else {
      value = event.target.value;
    }

    setFilterValues((lastValues) => ({ ...lastValues, [name]: value }));
  };

  const handleRefundClick = (transaction) => {
    confirmationModalTexAreaRef.current = null;
    setMessageConfirmationModal(`
      Do you want to refund transaction '${transaction.referenceNumber}?'`);
    setShowConfirmationModal(true);
  };

  const handlerConfirmationPopup = async (isConfirm) => {
    setShowConfirmationModal(false);

    if (isConfirm) {
      setIsLoading(true);

      const accessToken = await getAccessTokenSilently();
      const requestBody = {
        //transactionId: transactionToRefund.id,
        reason: confirmationModalTexAreaRef.current.value,
      };

      refundTransaction(accessToken, requestBody)
        .then((result) => {
          showNotification("Refund operation started.", ALERT_TYPES.success);
          sendSearchRequest();
        })
        .catch((error) => {
          console.error(error);
          showNotification(
            error?.response?.data?.responseMessage ?? error.message,
            ALERT_TYPES.error
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      InitializeComponent();
    }

    sendSearchRequest();
  }, [filterValues, currentPage, pageSize, sortConfig]);

  return (
    <>
      <Row>
        {isLoading && <CustomLoader className="loader-center-content" />}
        <Col md={12}>
          <Card className="card-user">
            <CardBody>
              <Form>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <label>Reference number</label>
                      <Input
                        name="referenceNumber"
                        type="text"
                        placeholder="Enter a reference number"
                        defaultValue={filterValues?.referenceNumber}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <label>Date from</label>
                      <DateInput
                        name="dateFrom"
                        defaultValue={filterValues?.dateFrom}
                        onChangeEventOut={handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <label>Date to</label>
                      <DateInput
                        name="dateTo"
                        defaultValue={filterValues?.dateTo}
                        onChangeEventOut={handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <label>Merchant</label>
                      <SelectInput
                        name="merchantId"
                        placeholder={"Select a merchant"}
                        options={filteringOptions?.merchants}
                        isMultiSelect={false}
                        isSearchable={true}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
              <Table hover>
                <thead className="text-primary">
                  <tr>
                    <th onClick={() => handleSort("referenceNumber")}>
                      Reference number
                      <SortIcon field="referenceNumber" sortConfig={sortConfig} />
                    </th>
                    <th onClick={() => handleSort("date")}>
                      Date
                      <SortIcon field="date" sortConfig={sortConfig} />
                    </th>
                    <th onClick={() => handleSort("merchant")}>
                      Merchant
                      <SortIcon field="merchant" sortConfig={sortConfig} />
                    </th>
                    <th className="text-center" onClick={() => handleSort("paymentMethod")}>
                      Payment Method
                      <SortIcon field="paymentMethod" sortConfig={sortConfig} />
                    </th>
                    <th className="text-center" onClick={() => handleSort("currencyTo")}>
                      Currency
                      <SortIcon field="currencyTo" sortConfig={sortConfig} />
                    </th>
                    <th className="text-center" onClick={() => handleSort("amount")}>
                      Amount
                      <SortIcon field="amount" sortConfig={sortConfig} />
                    </th>
                    <th className="text-center" onClick={() => handleSort("country")}>
                      Country
                      <SortIcon field="country" sortConfig={sortConfig} />
                    </th>
                    <th className="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  {!transactions || transactions.length == 0 ? (
                    <tr>
                      <td colSpan={12} className="text-center">
                        There are no records to show.
                      </td>
                    </tr>
                  ) : (
                    transactions.map((transaction, key) => {
                      return (
                        <tr key={key}>
                          <td>{transaction.referenceNumber}</td>
                          <td>{formatDate(new Date(transaction.date))}</td>
                          <td>{transaction.merchant}</td>
                          <td className="text-center">{transaction.paymentMethod}</td>
                          <td className="text-center">{transaction.currencyTo}</td>
                          <td className="text-center">{transaction.amount}</td>
                          <td className="text-center">{transaction.countryTo}</td>
                          <td className="text-center">
                            <Button color="link" onClick={() => handleRefundClick(transaction)}>
                              <MdCurrencyExchange fontSize="large" />
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
                <tfoot>
                  {transactions && (
                    <TableFooter
                      totalPages={totalPages}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      onPaginationValuesChange={handleChangePageOrSize}
                      colSpan={12}
                    />
                  )}
                </tfoot>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <ConfirmationPopup
        modalTitle="Refund confirmation"
        modalBody={messageConfirmationModal}
        isOpenModal={showConfirmationModal}
        onCloseModal={(e) => handlerConfirmationPopup(e)}
        isShowTextArea={true}
        textAreaLabel="Refund reason"
        textAreaPlaceholder="Enter a refund reason"
        textAreaError="You must enter a reason."
        validateTextArea={true}
        ref={confirmationModalTexAreaRef}
      />
    </>
  );
};

export default RefundList;
