import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { useEffect, useState } from "react";

const DateInput = ({ name, defaultValue, onChangeEventOut, defaultDateFormat = "MM/DD/YYYY" }) => {
  const [value, setValue] = useState();

  useEffect(() => {
    let dateValue = moment();
    if (defaultValue) {
      dateValue = moment(defaultValue, "YYYY-MM-DD");
    }

    setValue(dateValue);
  }, [defaultValue]);

  const handleChange = (input) => {
    let date = input;
    if (typeof input === "string") {
      date = moment(input, defaultDateFormat, true);
    }

    if (date && date.isValid()) {
      const event = {
        target: {
          name: name,
          value: date.toDate(), //.toISOString().split("T")[0],
        },
      };
      onChangeEventOut(event);
    }
  };

  return (
    <div>
      <Datetime
        value={value}
        onChange={handleChange}
        dateFormat={defaultDateFormat}
        timeFormat={false}
        closeOnSelect={true}
        locale="en-US"
        strictParsing={true}
      />
    </div>
  );
};

export default DateInput;
