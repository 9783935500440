import { useAuth0 } from "@auth0/auth0-react";
import jwt from "jwt-decode";
import { useSelector } from "react-redux";

const Auth0Helper = () => {
  const { getAccessTokenSilently } = useAuth0();

  const getEnabledCountries = async () => {
    const accessToken = await getAccessTokenSilently();

    const tokenInfo = jwt(accessToken);
    return tokenInfo.enabledCountries.split(",");
  };

  const getEnabledMerchantForPayment = async () => {
    const accessToken = await getAccessTokenSilently();

    const tokenInfo = jwt(accessToken);
    return tokenInfo.enabledMerchantsPayin.split(",");
  };

  const getRoles = async () => {
    const accessToken = await getAccessTokenSilently();

    const tokenInfo = jwt(accessToken);
    const roles = tokenInfo.userRol;

    return roles;
  };

  const getPermissions = async () => {
    const accessToken = await getAccessTokenSilently();

    const tokenInfo = jwt(accessToken);
    const permissions = tokenInfo.permissions;

    return permissions;
  };

  const validateUserRole = async (roleNeededList) => {
    if (!roleNeededList) {
      return true;
    }

    const userRoles = await getRoles();

    const valid = roleNeededList ? roleNeededList.some((x) => userRoles.includes(x)) : false;

    return valid;
  };

  const validateUserPermissions = async (permissionsNeededList) => {
    if (!permissionsNeededList || permissionsNeededList.length == 0) {
      return true;
    }

    const permissions = await getPermissions();

    const valid = permissionsNeededList
      ? permissionsNeededList.some((x) => permissions.includes(x))
      : false;

    return valid;
  };

  const getPayoutEnabledCountries = async () => {
    const accessToken = await getAccessTokenSilently();

    const tokenInfo = jwt(accessToken);
    return tokenInfo.enabledCountriesPayOut.split(",");
  };

  return {
    validateUserRole,
    validateUserPermissions,
    getEnabledCountries,
    getEnabledMerchantForPayment,
    getPayoutEnabledCountries,
  };
};

export default Auth0Helper;

export const AUTH0_SCOPES = [
  "profile",
  "email",
  "payin:getMerchantAggregator",
  "payin:postMerchantAggregator",
  "payin:putMerchantAggregator",
  "payin:deleteMerchantAggregator",
  "payin:catalogue",
  "payin-commission:getCalculation",
  "payin-commission:saveCalculation",
  "payin-commission:deleteCalculation",
  "payin-commission:getCalculationRange",
  "payin-commission:saveCalculationRange",
  "payin-commission:deleteCalculationRange",
  "payin-commission:getCommission",
  "payin-commission:saveCommission",
  "payin-commission:deleteCommission",
  "payin-commission:getAllCalculation",
  "payin-commission:getCalculationById",
  "payin:reports",
  "payin-commission:getRateTenant",
  "payin-commission:saveRateTenant",
  "payin-commission:deleteRateTenant",
  "payin:postRateBase",
  "payin:getRateBase",
  "payin:getRateBaseHistory",
  "payin-commission:getCurrentRateTenant",
  "payin-commission:exchangeRate",
  "payin:rateCalculator",
  "payin-dataSource:catalogue",
  "payin-catalogue:getMandatoryField",
  "payin-catalogue:saveMandatoryField",
  "payin-catalogue:deleteMandatoryField",
  "payin:getMerchantAggregatorContract",
  "payin:postMerchantAggregatorContract",
  "payin:deleteMerchantAggregatorContract",
  "payin:reportsAllMerchants",
  "payin-catalogue:getMetadata",
  "payin-catalogue:saveMetaData",
  "payin-catalogue:deleteMetadata",
  "payin:administration-listUser",
  "payin:administration-createUser",
  "payin:administration-blockUser",
  "payin:administration-updateUser",
  "payin:administration-listRoles",
  "payin:administration-listRolesByUser",
  "payin:administration-updateUserRoles",
  "payin:administration-allAgtId",
  "payin-catalogue:getTax",
  "payin-catalogue:saveTax",
  "payin-catalogue:deleteTax",
  "payin-catalogue:getCountryData",
  "payin-catalogue:saveCountryData",
  "payin-catalogue:deleteCountryData",
  "payin-catalogue:getCurrencyData",
  "payin-catalogue:saveCurrencyData",
  "payin-catalogue:deleteCurrencyData",
  "payin-catalogue:getMerchantAggregatorContact",
  "payin-catalogue:saveMerchantAggregatorContact",
  "payin-catalogue:deleteMerchantAggregatorContact",
  "payin:getPayment",
  "payin:postPayment",
  "payin:putPayment",
  "payin:genericAggregator",
  "payinHeaderMerchantAggregator",
  "conciliation:catalogue",
  "payinBackoffice_admin_access",
  "payinBackoffice_finance_access",
  "payinBackoffice_calculation_access",
  "payinBackoffice_rateBase_access",
  "payinBackoffice_commission_access",
  "payinBackoffice_rateLocal_access",
  "payinBackoffice_tax_access",
  "payinBackoffice_commercial_access",
  "payinBackoffice_operations_access",
  "payinBackoffice_utils_access",
  "payinBackoffice_users_access",
  "payinBackoffice_system_access",
  "payinBackoffice_payments_access",
  "payinBackoffice_checkPayments_access",
  "payinBackoffice_requestPayments_access",
  "payin-catalogue:payin",
  "pricing:paymentMethod",
];
