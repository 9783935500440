import axios from "axios";
import { getApiUrl, buildRequestHeaders } from "utils/Api";
import { CATALOGUE_URL_CONSTANTS } from "utils/Constants";

export const getFilteringOptions = async (accessToken) => {
  const urls = [`${getApiUrl()}${CATALOGUE_URL_CONSTANTS.getMerchantsCatalogue}`];

  const requests = urls.map((url) => axios.get(url, { headers: buildRequestHeaders(accessToken) }));

  return await axios.all(requests);
};

export const getTransactionForRefundList = async (accessToken, requestBody, tenant) => {
  return axios.get(`${getApiUrl()}/reports/getListTransaction`, {
    headers: buildRequestHeaders(accessToken),
    params: requestBody,
  });
};

export const refundTransaction = async (accessToken, requestBody) => {
  return axios.post(`${getApiUrl()}/reports/getListTransactionForRefund`, requestBody, {
    headers: buildRequestHeaders(accessToken),
  });
};
