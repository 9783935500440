import axios from "axios";
import { getApiUrl, buildRequestHeaders } from "utils/Api";
import { CATALOGUE_URL_CONSTANTS } from "utils/Constants";

const COUNTRY_ENDPOINT = "catalogue/catalogue/backoffice";

export const saveCountry = async (country, accessToken) => {
  const body = JSON.stringify(country);
  return await axios.post(
    `${getApiUrl()}${COUNTRY_ENDPOINT}/saveCountry`,
    body,
    {
      headers: buildRequestHeaders(accessToken),
    }
  );
};

export const searchCountry = async (filters, accessToken) => {
  return await axios.get(`${getApiUrl()}${COUNTRY_ENDPOINT}/getListCountries`, {
    headers: buildRequestHeaders(accessToken),
    params: filters,
  });
};

export const getCountryById = async (id, accessToken) => {
  return await axios.get(`${getApiUrl()}${COUNTRY_ENDPOINT}/getCountryById`, {
    headers: buildRequestHeaders(accessToken),
    params: {
      id: id,
    },
  });
};

export const deleteCountry = async (countryId, accessToken) => {
  return await axios.delete(
    `${getApiUrl()}${COUNTRY_ENDPOINT}/deleteCountry?id=${countryId}`,
    {
      headers: buildRequestHeaders(accessToken),
    }
  );
};

export const getEditorOptions = async (accessToken) => {
  const urls = [
    `${getApiUrl()}${CATALOGUE_URL_CONSTANTS.getCurrenciesCatalogue}`,
  ];

  const requests = urls.map((url) =>
    axios.get(url, { headers: buildRequestHeaders(accessToken) })
  );

  return axios.all(requests);
};
