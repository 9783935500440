import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const ConfirmationPopup = (props, ref) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [textAreaError, setTextAreaError] = useState();
  const [textAreaValue, setTextAreaValue] = useState();

  useEffect(() => {
    setIsOpenModal(props.isOpenModal);
    setTextAreaError(null);
    setTextAreaValue(null);
  }, [props.isOpenModal]);

  const toggleModal = () => setIsOpenModal(!isOpenModal);

  const onCloseButtonClick = () => {
    if (props.validateTextArea) {
      if (!textAreaValue?.trim() || textAreaValue?.length == 0) {
        setTextAreaError(props.textAreaError);
        return;
      }
    }

    props.onCloseModal(true);
    toggleModal();
  };

  return (
    <Modal
      id="confirmationModal"
      isOpen={isOpenModal}
      toggle={toggleModal}
      className={props.className}
      onClosed={props.onCloseModal}
      zIndex={9999}
      size="md"
      centered
      scrollable
    >
      <ModalHeader toggle={toggleModal}>{props.modalTitle}</ModalHeader>
      <ModalBody>
        <h3 className="text-center m-1 primary-orange-color">
          <FontAwesomeIcon icon="far fa-question-circle" />
        </h3>
        <Row className="justify-content-center m-1 text-center">
          {props.modalBody}

          {props.isShowTextArea && (
            <FormGroup className="mt-4 w-100">
              <Label for="reasonText">{props.textAreaLabel}</Label>
              <Input
                type="textarea"
                id="reasonText"
                value={textAreaValue}
                placeholder={props.textAreaPlaceholder}
                innerRef={ref}
                className={textAreaError ? "required-input" : ""}
                onChange={(e) => setTextAreaValue(e.target.value)}
              />
              {textAreaError && <p className="error-message">{textAreaError}</p>}
            </FormGroup>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="primary" onClick={() => onCloseButtonClick()}>
          Accept
        </Button>
        <Button type="button" color="primary" onClick={toggleModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ConfirmationPopup.prototypes = {
  modalTitle: PropTypes.string,
  modalBody: PropTypes.string,
  isOpenModal: PropTypes.bool,
  className: PropTypes.string,
  onCloseModal: PropTypes.func,
  isShowTextArea: PropTypes.bool,
  textAreaLabel: PropTypes.string,
  textAreaPlaceholder: PropTypes.string,
  textAreaValue: PropTypes.string,
  textAreaError: PropTypes.string,
  validateTextArea: PropTypes.bool,
};

export default React.forwardRef(ConfirmationPopup);
